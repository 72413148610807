import safeDateFns, { safeConvertToZonedTime, safeFormatInTimeZone } from '@/helpers/safeFormat';
import { Order } from '@/types/schema';
import { NewStandingData, StandingData } from '@/types/standing';
import {
	add,
	addDays,
	addMonths,
	addWeeks,
	addYears,
	format,
	getDay,
	isAfter,
	isBefore,
	isSameDay,
	min,
	setDay,
} from 'date-fns';
import { isEmpty } from 'lodash-es';

const MAX_OCCURRENCES_CHECK = 104;

// Utility to get the next occurrence of a day of the week from a date
export function findEarliestDate( standingData: StandingData, timezone?: string ) {
	if ( !standingData ) return;
	const includeDates = standingData.include?.map( ( d ) => new Date( d ) ).sort( ( a: any, b: any ) => a - b );
	const excludeDates = standingData.exclude?.map( ( d ) => new Date( d ) );
	const startDate = new Date( standingData.startDate );
	const dayMapping = { Su: 0, M: 1, Tu: 2, W: 3, Th: 4, F: 5, Sa: 6 };
	
	// Find the earliest included date if it exists and is before the startDate
	let earliestDate = includeDates?.length > 0 && includeDates[ 0 ] < startDate ? includeDates[ 0 ] : startDate;
	
	// Adjust the earliestDate based on exclude and type directly
	switch ( standingData.type ) {
		case 'MONTH':
		case 'YEAR': {
			const adjustmentFunction = standingData.type === 'MONTH' ? addMonths : addYears;
			
			// Directly inside the switch, adjust for excluded dates
			if ( excludeDates.some( ( exDate ) => isSameDay( exDate, startDate ) ) ) {
				let tempDate = adjustmentFunction( startDate, standingData.multiple );
				while ( excludeDates.some( ( exDate ) => isSameDay( exDate, tempDate ) ) ) {
					tempDate = adjustmentFunction( tempDate, standingData.multiple );
				}
				earliestDate = tempDate;
			}
			
			// Re-check against include dates after potential adjustment
			const postAdjustmentInclude = includeDates.find( ( d ) => d >= startDate && d < earliestDate );
			if ( postAdjustmentInclude ) {
				earliestDate = postAdjustmentInclude;
			}
			break;
		}
		case 'WEEK': {
			// Create an array of the week days marked for repetition
			const repeatDays = Object.entries( standingData.repeat )
				.filter( ( [ _, shouldRepeat ] ) => shouldRepeat )
				.map( ( [ day ] ) => dayMapping[ day ] );
			
			const nextValidDates = [];
			
			// Iterate over each repeat day to find the next valid occurrences
			for ( const dayOfWeek of repeatDays ) {
				let nextDate = new Date( startDate );
				// Adjust for the first occurrence of this weekday after the start date
				const daysToAdd = ( dayOfWeek + 7 - nextDate.getDay() ) % 7;
				if ( daysToAdd > 0 ) {
					nextDate = addDays( nextDate, daysToAdd );
				}
				
				let checks = 0; // Add a counter to prevent infinite loops
				
				while ( checks < MAX_OCCURRENCES_CHECK ) {
					// Check if the date is not in excludes, then it's a valid date
					if ( !excludeDates.some( ( exDate ) => isSameDay( exDate, nextDate ) ) ) {
						nextValidDates.push( new Date( nextDate ) ); // Copy the date to prevent mutation
					}
					
					// Move to the next occurrence based on 'multiple'
					nextDate = addWeeks( nextDate, standingData.multiple );
					checks += 1;
				}
			}
			
			// After gathering all next valid dates, sort them to find the earliest
			if ( nextValidDates.length > 0 ) {
				nextValidDates.sort( ( a, b ) => a.getTime() - b.getTime() );
				// Compare against the earliest included date, if any
				const earliestIncluded = includeDates.length > 0 ? min( includeDates ) : null;
				earliestDate = earliestIncluded && earliestIncluded < nextValidDates[ 0 ]
					? earliestIncluded
					: nextValidDates[ 0 ];
			}
			
			break;
		}
		case 'NONE':
			earliestDate = includeDates.length > 0 ? min( includeDates ) : startDate;
	}
	
	return safeConvertToZonedTime( earliestDate, timezone );
}

export function isStandingDate( date: Date, values: StandingData ) {
	const days = [ 'Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa' ];
	if ( !isEmpty( values.include ) && values.include.find( ( value ) => isSameDay( date, value ) ) ) return true;
	if ( !isEmpty( values.exclude ) && values.exclude.find( ( value ) => isSameDay( date, value ) ) ) return false;
	
	const initialStartDate = values.startDate;
	
	function loop( startDate, unit ) {
		let loopDate = startDate;
		let count = 0;
		while ( loopDate < date || isSameDay( loopDate, date ) ) {
			if ( values.ends === 'OCCURRENCES' && count >= values.occurrences
				|| values.ends === 'DATE' && date > values.endDate ) break;
			
			if ( isSameDay( date, loopDate ) ) return true;
			
			++count;
			loopDate = add( startDate, { [ unit ]: count * values.multiple } );
		}
		return false;
	}
	
	switch ( values.type ) {
		case 'WEEK': {
			const day = getDay( date );
			if ( values.repeat[ days[ day ] ] ) {
				let startDate = setDay( initialStartDate, day );
				if ( startDate < initialStartDate ) startDate = add( startDate, { weeks: 1 } );
				return loop( startDate, 'weeks' );
			}
			break;
		}
		case 'MONTH':
			return loop( initialStartDate, 'months' );
		case 'YEAR':
			return loop( initialStartDate, 'years' );
	}
	
	return false;
}

export function getAllOccurrencesOfWeekdayFromStartDate(
	weekdayIndex: number,
	startDate: Date,
	endDate: Date = null,
	multiple: number = 1,
) {
	const occurrences = [];
	let nextOccurrence = startDate;
	
	// If startDate is not the desired weekday, adjust to the next occurrence of that weekday
	while ( getDay( nextOccurrence ) !== weekdayIndex ) {
		nextOccurrence = addDays( nextOccurrence, 1 );
	}
	
	// Adjust the end date to be either the provided endDate or a calculated fallback based on MAX_OCCURRENCES_CHECK
	const effectiveEndDate = endDate
		? new Date( endDate )
		: addWeeks( nextOccurrence, ( MAX_OCCURRENCES_CHECK - 1 ) * multiple );
	
	// Collect occurrences, moving by `multiple` weeks each time until reaching the effective end date or hitting MAX_OCCURRENCES_CHECK
	while ( isBefore( nextOccurrence, effectiveEndDate ) && occurrences.length < MAX_OCCURRENCES_CHECK ) {
		occurrences.push( new Date( nextOccurrence ) );
		nextOccurrence = addWeeks( nextOccurrence, multiple ); // Advance by `multiple` weeks
	}
	
	return occurrences;
}

export const getDueDateInfo = ( value: Order ) => {
	const DueToday = +value?.dueDate && safeDateFns.isSameDay( safeConvertToZonedTime( value.dueDate ), new Date() );
	const PastDue = +value?.dueDate && safeDateFns.isBefore( safeConvertToZonedTime( value.dueDate ), new Date() );
	const pastDueDays = +value?.dueDate && safeDateFns.differenceInCalendarDays( new Date(), safeConvertToZonedTime( value.dueDate ) );
	const shouldShowWarning = ( status ) => PastDue && ![ 'PAID', 'MERGED', 'CANCELLED' ].includes( status );
	
	const getDueDateText = (): string => {
		const { status, standingData, dueDate } = value;
		if ( status !== 'PAID' && isEmpty( standingData ) && +dueDate ) {
			if ( DueToday ) return 'Due Today';
			if ( PastDue ) return `${pastDueDays} Day${pastDueDays > 1 ? 's' : ''} Past Due`;
		}
		if ( value.standingActive && value.duePeriod > 0 ) {
			return `${value.duePeriod} Days`;
		}
		return safeFormatInTimeZone( dueDate, 'PPp' );
	};
	
	const getDueDateColor = (): string | undefined => {
		const { status, standingData, dueDate } = value;
		if ( status !== 'PAID' && isEmpty( standingData ) && +dueDate ) {
			if ( PastDue && !DueToday && ![ 'MERGED', 'CANCELLED' ].includes( status ) ) {
				return 'error.main';
			}
			if ( DueToday ) {
				return 'warning.main';
			}
		} else if ( shouldShowWarning( status ) ) {
			return 'warning.main';
		}
		return undefined;
	};
	
	return { getDueDateText, getDueDateColor };
};

export function getRecurringTypeInfo( recurringType: 'create' | 'send' | 'charge' ) {
	switch ( recurringType ) {
		case 'create':
			return 'Create an invoice and do not send to client';
		case 'send':
			return 'Create an invoice and email it to client';
		case 'charge':
			return 'Create an Invoice, charge and send a receipt to client';
		default:
			return '-';
	}
}

export function findNextOccurrenceDate( standingData: StandingData ) {
	if ( !standingData ) return null;
	const { startDate, endDate, type, multiple = 1, include = [], exclude = [], repeat, occurrences = 0 } = standingData;
	if ( !startDate ) return null;
	
	let nextOccurrence = new Date( startDate );
	const today = new Date();
	let occurrenceCount = 0; // Start with first occurrence as the startDate
	
	// 1. If startDate is today or in the future, return it as the next occurrence
	if ( nextOccurrence >= today && !exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) ) ) {
		return nextOccurrence;
	}
	
	let found = false;
	
	// Sort included dates to find the immediate next date after startDate
	const sortedIncludes = include.sort( ( a: string | Date,
		b: string | Date ) => new Date( a ).getTime() - new Date( b ).getTime() );
	
	// Check for included dates that are after today
	for ( const incDate of sortedIncludes ) {
		if ( new Date( incDate ) > today ) {
			nextOccurrence = new Date( incDate );
			found = true;
			break;
		}
	}
	
	if ( !found ) {
		let attempts = 0;
		const dayMapping = { Su: 0, M: 1, Tu: 2, W: 3, Th: 4, F: 5, Sa: 6 };
		
		while ( attempts < 24 && ( occurrences === 0 || occurrenceCount < occurrences ) ) {
			if ( type === 'WEEK' ) {
				// Handle weekly occurrences based on repeat days
				const weekdays = Object.keys( repeat ).map( ( day ) => dayMapping[ day ] );
				
				do {
					nextOccurrence = addDays( nextOccurrence, 1 ); // Move day by day
				} while ( !weekdays.includes( getDay( nextOccurrence ) ) || exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) ) );
				
				// Apply 'multiple' for weekly skips
				for ( let weekSkip = 1; weekSkip < multiple; weekSkip++ ) {
					nextOccurrence = addWeeks( nextOccurrence, 1 ); // Skip weeks as per 'multiple'
				}
				found = true;
			} else if ( type === 'MONTH' ) {
				// Handle monthly occurrences, incrementing by 'multiple' months
				do {
					nextOccurrence = addMonths( nextOccurrence, multiple ); // Increment by 'multiple' months
				} while ( nextOccurrence <= today && ( occurrences === 0 || occurrenceCount < occurrences ) ); // Ensure we move past today's date
				
				// If next occurrence is after today, break
				if ( nextOccurrence > today ) {
					break;
				}
				
				// Check for endDate
				if ( endDate && nextOccurrence > new Date( endDate ) ) return null;
				
				found = true;
			} else if ( type === 'YEAR' ) {
				// Handle yearly occurrences, incrementing by 'multiple' years
				while ( ( nextOccurrence < today || exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) ) ) && ( occurrences === 0 || occurrenceCount < occurrences ) ) {
					nextOccurrence = addYears( nextOccurrence, multiple ); // Increment by 'multiple' years
					if ( endDate && nextOccurrence > new Date( endDate ) ) return null; // Check for endDate
				}
				found = true;
			}
			
			// Stop once the occurrences limit is reached
			if ( occurrences > 0 && occurrenceCount >= occurrences ) {
				if ( nextOccurrence < today ) {
					// If the last occurrence has already passed
					return null;
				}
				break;
			}
			
			occurrenceCount++; // Increment the count for each valid occurrence
			attempts++;
		}
	}
	// If nextOccurrence is after endDate, return null
	if ( endDate && nextOccurrence > new Date( endDate ) ) return null;
	
	// If the next occurrence is after today and not in the exclude list, return it
	return nextOccurrence > today && !exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) )
		? nextOccurrence
		: null;
}

export function findNextDate( standingData: StandingData ) {
	if ( !standingData ) return null;
	const { startDate, endDate, type, multiple = 1, include = [], exclude = [], repeat, occurrences = 0 } = standingData;
	if ( !startDate ) return null;
	
	let nextOccurrence = new Date( startDate );
	const today = new Date();
	let occurrenceCount = 0; // Start with first occurrence as the startDate
	
	// 1. If startDate is today or in the future, return it as the next occurrence
	if ( nextOccurrence >= today && !exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) ) ) {
		return nextOccurrence;
	}
	
	let found = false;
	
	// Sort included dates to find the immediate next date after startDate
	const sortedIncludes = include.sort( ( a: string | Date,
		b: string | Date ) => new Date( a ).getTime() - new Date( b ).getTime() );
	
	// Check for included dates that are after today
	for ( const incDate of sortedIncludes ) {
		if ( new Date( incDate ) > today ) {
			nextOccurrence = new Date( incDate );
			found = true;
			break;
		}
	}
	
	if ( !found ) {
		let attempts = 0;
		const dayMapping = { Su: 0, M: 1, Tu: 2, W: 3, Th: 4, F: 5, Sa: 6 };
		
		while ( attempts < 24 && ( occurrences === 0 || occurrenceCount < occurrences ) ) {
			if ( type === 'WEEK' ) {
				// Handle weekly occurrences based on repeat days
				const weekdays = Object.keys( repeat ).map( ( day ) => dayMapping[ day ] );
				
				do {
					nextOccurrence = addDays( nextOccurrence, 1 ); // Move day by day
				} while ( !weekdays.includes( getDay( nextOccurrence ) ) || exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) ) );
				
				// Apply 'multiple' for weekly skips
				for ( let weekSkip = 1; weekSkip < multiple; weekSkip++ ) {
					nextOccurrence = addWeeks( nextOccurrence, 1 ); // Skip weeks as per 'multiple'
				}
				found = true;
			} else if ( type === 'MONTH' ) {
				// Handle monthly occurrences, incrementing by 'multiple' months
				do {
					nextOccurrence = addMonths( nextOccurrence, multiple ); // Increment by 'multiple' months
				} while ( nextOccurrence <= today && ( occurrences === 0 || occurrenceCount < occurrences ) ); // Ensure we move past today's date
				
				// If next occurrence is after today, break
				if ( nextOccurrence > today ) {
					break;
				}
				
				// Check for endDate
				if ( endDate && nextOccurrence > new Date( endDate ) ) return null;
				
				found = true;
			} else if ( type === 'YEAR' ) {
				// Handle yearly occurrences, incrementing by 'multiple' years
				while ( ( nextOccurrence < today || exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) ) ) && ( occurrences === 0 || occurrenceCount < occurrences ) ) {
					nextOccurrence = addYears( nextOccurrence, multiple ); // Increment by 'multiple' years
					if ( endDate && nextOccurrence > new Date( endDate ) ) return null; // Check for endDate
				}
				found = true;
			}
			
			// Stop once the occurrences limit is reached
			if ( occurrences > 0 && occurrenceCount >= occurrences ) {
				if ( nextOccurrence < today ) {
					// If the last occurrence has already passed
					return null;
				}
				break;
			}
			
			occurrenceCount++; // Increment the count for each valid occurrence
			attempts++;
		}
	}
	
	// If nextOccurrence is after endDate, return null
	if ( endDate && nextOccurrence > new Date( endDate ) ) return null;
	
	// If the next occurrence is after today and not in the exclude list, return it
	return nextOccurrence > today && !exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) )
		? nextOccurrence
		: null;
}

// Helper functions
// function addDays( date: Date, days: number ): Date {
// 	const newDate = new Date( date );
// 	newDate.setDate( newDate.getDate() + days );
// 	return newDate;
// }
//
// function addWeeks( date: Date, weeks: number ): Date {
// 	return addDays( date, weeks * 7 );
// }
//
// function addMonths( date: Date, months: number ): Date {
// 	const newDate = new Date( date );
// 	newDate.setMonth( newDate.getMonth() + months );
// 	return newDate;
// }
//
// function addYears( date: Date, years: number ): Date {
// 	const newDate = new Date( date );
// 	newDate.setFullYear( newDate.getFullYear() + years );
// 	return newDate;
// }

// function isSameDay( date1: Date, date2: Date ): boolean {
// 	return date1.getFullYear() === date2.getFullYear()
// 		&& date1.getMonth() === date2.getMonth()
// 		&& date1.getDate() === date2.getDate();
// }
//
// function getDay( date: Date ): number {
// 	return date.getDay();
// }

export function findNextOccurDate( standingData: NewStandingData ): string | null {
	if ( !standingData ) return null;
	const { startDate, type, multiple, include = [], exclude = [], repeat } = standingData;
	if ( !startDate ) return null;
	let nextOccurrence = new Date( startDate );
	let found = false;
	
	const sortedIncludes = include
		.map( ( date ) => new Date( date ) )
		.sort( ( a, b ) => a.getTime() - b.getTime() );
	
	for ( const incDate of sortedIncludes ) {
		if ( isAfter( incDate, new Date( startDate ) ) ) {
			nextOccurrence = incDate;
			found = true;
			break;
		}
	}
	
	if ( !found ) {
		let attempts = 0;
		const dayMapping = { Su: 0, M: 1, Tu: 2, W: 3, Th: 4, F: 5, Sa: 6 };
		
		while ( attempts < 24 && !found ) { // Improved safety limit
			if ( type === 'WEEK' ) {
				const weekdays = Object.keys( repeat ).map( ( day ) => dayMapping[ day ] );
				while ( !weekdays.includes( getDay( nextOccurrence ) ) || exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) ) ) {
					nextOccurrence = addDays( nextOccurrence, 1 );
				}
				
				for ( let weekSkip = 1; weekSkip < multiple; weekSkip++ ) {
					nextOccurrence = addWeeks( nextOccurrence, 1 );
				}
				found = true;
			} else if ( type === 'MONTH' ) {
				nextOccurrence = addMonths( nextOccurrence, multiple );
			} else if ( type === 'YEAR' ) {
				nextOccurrence = addYears( nextOccurrence, multiple );
			}
			
			if ( type !== 'WEEK' && !exclude.some( ( exDate ) => isSameDay( new Date( exDate ), nextOccurrence ) ) ) {
				found = true;
			}
			attempts++;
		}
	}
	
	if ( !found ) return null;
	return format( nextOccurrence, 'yyyy-MM-dd' );
}
