import currencyFormat from '@/helpers/currencyFormat';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { Fragment } from 'react';

export default function Tips( {
	cloverTips,
	selectedTipOption,
	setSelectedTipOption,
	setTip,
	setDollarTip,
	paying,
	type,
}: {
	cloverTips?: { name: string, percentage: number }[],
	selectedTipOption: number,
	setSelectedTipOption: ( index: number ) => void,
	setTip: ( tip: number ) => void,
	setDollarTip: ( tip: number ) => void,
	paying: number,
	type: 'Invoiss' | 'Clover'
} ) {
	
	if ( type === 'Invoiss' ) {
		return (
			<Fragment>
				{[ 10, 15, 20 ].map( ( tipPercent, index ) => (
					<Grid
						key={index}
						item
						xs={4}
						sx={{
							'.MuiTypography-root': {
								color: selectedTipOption === index ? 'primary.main' : undefined,
							},
						}}>
						<Card
							sx={{
								height     : '100%',
								border     : 2,
								borderColor: selectedTipOption === index ? 'primary.main' : 'divider',
							}}>
							<CardActionArea
								onClick={() => {
									setTip( tipPercent );
									setDollarTip( 0 );
									setSelectedTipOption( index );
								}}>
								<CardContent>
									<Typography variant='h5' textAlign='center' style={{ fontSize: 22 }}>
										{tipPercent}%
									</Typography>
									<Typography color='text.secondary' textAlign='center'>
										{currencyFormat( tipPercent * paying / 100 )}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				) )}
			</Fragment>
		);
		
	}
	
	if ( type === 'Clover' ) {
		return (
			<Fragment>
				{cloverTips.map( ( tip, index ) => (
					<Grid
						key={index}
						item
						xs={3}
						sx={{
							'.MuiTypography-root': {
								color: selectedTipOption === index ? 'primary.main' : undefined,
							},
						}}>
						<Card
							sx={{
								height     : '100%',
								border     : 2,
								borderColor: selectedTipOption === index ? 'primary.main' : 'divider',
							}}>
							<CardActionArea
								sx={{ height: '100%', px: 1, py: 2 }}
								onClick={() => {
									setTip( tip.percentage );
									setDollarTip( 0 );
									setSelectedTipOption( index );
								}}>
								<CardContent sx={{ p: 0 }}>
									<Typography
										textAlign='center'
										style={{ fontSize: 18, fontWeight: 'bold', justifyContent: 'space-between' }}>
										{tip.percentage}%
									</Typography>
									<Typography color='text.secondary' textAlign='center'>
										{currencyFormat( tip.percentage * paying / 100 )}
									</Typography>
									<Typography textAlign='center'>
										{tip.name}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				) )}
			</Fragment>
		);
		
	}
	
}
