import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import type { GraphqlProviderProps } from './graphqlProvider';
import GraphqlProvider from './graphqlProvider';

export default function PageGraphqlProvider<S = any, T = any>( { children, ...props }: GraphqlProviderProps<S, T> ) {
	return (
		<GraphqlProvider {...props}>
			{( result: any ) => {
				if ( result.deletedAt ) {
					return (
						<Box textAlign='center' py={2}>
							<Typography>
								Entry Deleted on {format( result.deletedAt, 'Pp' )}
							</Typography>
						</Box>
					);
				}
				
				return typeof children === 'function' ? children( result ) : children;
			}}
		</GraphqlProvider>
	);
}
